import {Injectable} from '@angular/core';
import {PaymentService} from '../payment/payment.service';
import {AuthService} from '../core/auth.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class IsPlanActiveService {

  subscriptions$: any;

  isPlanIdOvTrackerLearningCyclesActive = new BehaviorSubject(false);
  isPlanIdOvTrackerSheetsActive = new BehaviorSubject(false);

  constructor(private paymentService: PaymentService,
              private auth: AuthService) {
  }

  getPlans() {
    this.subscriptions$ = this.paymentService.getCustomer()
      .subscribe(user => {
        const subs = (user.subscriptions.data as any[]);
        subs.forEach(sub =>  {
          // console.log(sub);
            if (sub.status === 'active') {
              if (sub.plan.id === 'OvTrackerSheets') {
                this.isPlanIdOvTrackerSheetsActive.next(true)
              }
              if (sub.plan.id === 'OvTrackerLearningCycles') {
                this.isPlanIdOvTrackerLearningCyclesActive.next(true)
              }
            }

        })
      }, (err) => console.log(err));
  }

}
