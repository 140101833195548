import {Component} from '@angular/core';
import {AuthService} from '../core/auth.service';
import {Router} from '@angular/router';
import {IsPlanActiveService} from '../services/is-plan-active.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(public auth: AuthService,
              private router: Router,
              private isPlanActiveService: IsPlanActiveService) {
  }

  googleLogin() {
    this.auth.googleLogin()
      .then(
        (credential) => {
          const isNewUser: boolean = credential.additionalUserInfo.isNewUser;
          this.isPlanActiveService.getPlans();
          // console.log('credential ', credential);
          // console.log('isNewuser ', isNewUser);
          if (isNewUser) {
            this.router.navigate(['/get-started']);
          }
        }
      )
  }
}
