import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';

import { Observable ,  from as fromPromise } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { User } from './user';
import { DataService } from '../services/data-service';

@Injectable()
export class AuthService {

  user: Observable<User>;
  isLoggedIn = false;

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router,
              private dataService: DataService) {

      //// Get auth data, then get firestore user document || null
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            this.isLoggedIn = true;
            this.dataService.setUid({uid: user.uid});
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
          } else {
            return Observable.of(null)
          }
        })
      )
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then(credential => {
        // console.log('credential:', credential);
        this.updateUserData(credential.user);
        this.isLoggedIn = true;
        return credential;
      })
  }

    // Sets user data to Firestore on login
  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || null,
      photoURL: user.photoURL || null
    };
    return userRef.set(data, { merge: true })
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.isLoggedIn = false;
      this.router.navigate(['/']);
    });
  }

  // Used by the http interceptor to set the auth header
  getUserIdToken(): Observable<string> {
    return fromPromise ( this.afAuth.auth.currentUser.getIdToken() );
  }

  isUserAuthenticated() {
    console.log(this.isLoggedIn)
    return this.isLoggedIn;
  }

  ///// STRIPE CONNECT //////


  // Login popup window
  stripeLogin() {
    const popup = window.open(`${environment.functionsURL}/stripeRedirect`, '_blank', 'height=700,width=800')
  }
  // Signin with a custom token from
  customSignIn(token) {
    return this.afAuth.auth.signInWithCustomToken(token).then(() => window.close())
  }

}
