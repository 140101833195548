import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService} from './auth.service'
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (this.auth.isUserAuthenticated()) {
      return true;
    }

    this.router.navigate(['/']);

    alert('Please first log in!');
    // TODO: bug blank page loads: https://github.com/angular/angular/issues/16211
  }

}
