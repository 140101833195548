import {Component} from '@angular/core';
import {AuthService} from '../../core/auth.service';

@Component({
  selector: 'app-subscription-options',
  templateUrl: './subscription-options.component.html',
  styleUrls: ['./subscription-options.component.css']
})
export class SubscriptionOptionsComponent {

  sourceId: string;

  userSubscriptions;

  constructor(private auth: AuthService) {
  }

  setSource(e) {
    this.sourceId = e.id
  }

}
