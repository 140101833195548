import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'read-me',
  templateUrl: './read-me.component.html',
  styleUrls: ['./read-me.component.sass']
})
export class ReadMeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
