import { Component } from '@angular/core';

@Component({
  selector: 'stripe-dashboard',
  templateUrl: './stripe-dashboard.component.html',
  styleUrls: ['./stripe-dashboard.component.sass']
})
export class StripeDashboardComponent {

  constructor() { }

}
