import { Component } from '@angular/core';

@Component({
  selector: 'app-charge-card',
  templateUrl: './charge-card.component.html',
  styleUrls: ['./charge-card.component.sass']
})
export class ChargeCardComponent {

  constructor() { }

}
