import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../core/auth.service';
import {User} from '../../core/user';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  user: User;

  constructor(public auth: AuthService) {
  }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.user.subs = Object.keys(user.subscriptions).map(key => {
          const obj = {};
          obj['name'] = key;
          obj['status'] = user.subscriptions[key];
          return obj;
        })
      }
      // console.log(this.user);
    })
  }

}
